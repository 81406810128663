const isLive = import.meta.env.VITE_VERCEL_ENV === 'production'
const isStaging = import.meta.env.VITE_VERCEL_ENV === 'staging'
const isDev = import.meta.env.VITE_VERCEL_ENV === 'development'
const isPreview = import.meta.env.VITE_VERCEL_ENV === 'preview'
const isSchool = import.meta.env.VITE_BRAND === 'schoolflat'
const localStrapiApiUrl = import.meta.env.DEV
  ? import.meta.env.VITE_LOCAL_STRAPI_API_URL
  : undefined
const isTestEnv = import.meta.env.DEV || isPreview
export const isRealEnv = isLive || isStaging || isDev

const currentPhase = import.meta.env.VITE_VERCEL_ENV

const envNameForSentry = (() => {
  if (isLive) {
    return 'live'
  }
  if (isStaging) {
    return 'staging'
  }
  if (isDev) {
    return 'dev'
  }

  return 'unknown'
})()

const redirectToBasicStudentApp = () => {
  const basicStudentAppUrl = import.meta.env.VITE_VERCEL_BASIC_STUDENT_APP_URL
  const isTestEnv = import.meta.env.DEV || isPreview
  const redirectUrl = isTestEnv ? window.location.origin : basicStudentAppUrl

  window.location.replace(redirectUrl + '#/logout')
}

export const envUtils = {
  isLive,
  isStaging,
  isDev,
  isTestEnv,
  isRealEnv,
  currentPhase,
  envNameForSentry,
  redirectToBasicStudentApp,
  isSchool,
  localStrapiApiUrl,
}
