import styled from '@emotion/styled'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import type { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'
import { observer } from 'mobx-react'
import { isTablet } from 'react-device-detect'

import type { ProblemScoringColl } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import type { ProblemScoringFooterProps } from '~/@pages/@common/(ProblemScoring)/ProblemScoringCard/ProblemScoringFooter'

import { HandwrittenNoteInfoTooltip } from '../../Tooltip/HandwrittenNoteInfoTooltip'
import { WorksheetScoringByOneBodyMobile } from './(Body)/WorksheetScoringByOneBody.mobile'
import { WorksheetScoringByOneSwiperController } from './(Header)/WorksheetScoringByOneSwiperController'

type NotFetched = undefined

type Props<T extends '출제' | '미출제'> = {
  studentWorksheet: (T extends '출제' ? StudentWorksheetEntity.StudentWorksheet : null) | NotFetched
  problemScoringColl: ProblemScoringColl<'WORKSHEET'> | NotFetched
  viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'> | NotFetched
  type: WorksheetEntity.Worksheet['type'] | NotFetched
  isLastScreen?: boolean
  problemIndex?: number
  onProblemIndexChange?: (index: number) => void
  onSubmit?: () => void
  onLocalUserInputChange?: ProblemScoringFooterProps['onLocalUserInputChange']
}

const WorksheetScoringByOneMobile = observer(<T extends '출제' | '미출제'>(props: Props<T>) => {
  if (!props.problemScoringColl || !props.viewOption || !props.type) {
    return <S.Container />
  }

  return (
    <S.Container>
      <WorksheetScoringByOneSwiperController
        type={props.type}
        totalProblemCount={props.problemScoringColl.toArr.length}
      />
      <WorksheetScoringByOneBodyMobile {...props} />
      {isTablet && <HandwrittenNoteInfoTooltip className="hand-written-info-tooltip" />}
    </S.Container>
  )
})

export default WorksheetScoringByOneMobile

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;

    .hand-written-info-tooltip {
      left: 36px;
      bottom: 200px;
      width: calc(100% - 72px);
    }
  `,
}
