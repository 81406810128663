import type { MathflatApi as ExamApi } from '@mathflat/domain/@entities/StudentExam/api'
import type { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'

import type { AssignedStudentExam } from '~/@pages/student/student-worksheet/@common/types'

import maxios from '../utils/maxios'

export const studentExamApi = (() => {
  return {
    getStudentExams: ({
      studentId,
      dateTime: { startDate, endDate },
    }: {
      studentId: string
      dateTime: {
        startDate: string
        endDate: string
      }
    }) => {
      return maxios.get<(AssignedStudentExam & StudentWorksheetEntity.StudentWorksheet)[]>(
        `/student-exam?studentId=${studentId}&startDate=${startDate}&endDate=${endDate}`,
      )
    },
    getStudentExam: (studentExamId: string) => {
      return maxios.get<ExamApi.Response.StudentExamScoring>(`/student-exam/${studentExamId}`)
    },
    getStudentExamWithProblems: (studentExamId: string) => {
      return maxios.get<ExamApi.Response.ExamProblem[]>(`/exam/${studentExamId}/problem`)
    },

    patchStudentExamAutoScoringById: (
      studentExamId: string,
      scoringList: ExamApi.Request.StudentExamAutoScoring[],
    ) => {
      return maxios.patch(`/student-exam/${studentExamId}/auto-scoring`, scoringList)
    },

    postStudentExamStart: (studentExamId: number) => {
      return maxios.post(`/student-exam/${studentExamId}/start`)
    },

    postStudentExamSolve: (studentExamId: string) => {
      return maxios.post(`/student-exam/${studentExamId}/solve`)
    },

    getStudentExamRemainingTime: (studentExamId: number) => {
      return maxios.get<ExamApi.Response.ExamRemainingTime>(
        `/student-exam/${studentExamId}/remaining-time`,
      )
    },

    // 시험지는 자동채점만 가능합니다.
    // patchStudentExamScoringById: (
    //   studentExamId: string,
    //   scorings: {
    //     examProblemId: number
    //     result: Entity.Scoring<'WORKSHEET'>['result']
    //   }[],
    // ) => {
    //   return maxios.patch(`/student-exam/${studentExamId}/scoring`, scorings)
    // },
  }
})()
