import modalService from '~/@common/services/modal.service'
import Modal from '~/@common/ui/modal/Modal'

const MAATAlreadyCompleteModal = ({ onConfirm }: { onConfirm?: () => void }) => {
  return (
    <Modal.Alert
      theme="primary"
      confirm={{
        onClick: () => {
          onConfirm?.()
          modalService.closeModal()
        },
      }}
    >
      제출 이후에는 <br />
      시험지를 확인할 수 없습니다.
      <br />
      <br />
      결과지와 보고서는 <br />
      12월 30일 이후 선생님께 요청하세요.
      <br />
    </Modal.Alert>
  )
}

export default MAATAlreadyCompleteModal
