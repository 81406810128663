import styled from '@emotion/styled'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { studentExamApi } from '~/@common/api'
import { routeName } from '~/@common/constants'
import { useRepository } from '~/@common/hooks/useRepository'
import useTimer from '~/@common/hooks/useTimer'
import { CustomEventService } from '~/@common/services/event.service'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import Modal from '~/@common/ui/modal/Modal'
import { studentHomeApi } from '~/@pages/student/student-home/@common/api'
import type { RecentStudyItem } from '~/@pages/student/student-home/@common/response'
import RecentStudyService from '~/@pages/student/student-home/@service/RecentStudy.service'

import { StudentExamService } from '../@service/StudentExamPage.service'

const MAATTimer = () => {
  const navigate = useNavigate()
  const [hideTimer, setHideTimer] = useState(false)
  const [MAATExam, setMAATExam] = useState<null | RecentStudyItem>(null)

  const service = useRepository(StudentExamService)
  const recentStudyService = useRepository(RecentStudyService)

  const { minute, second, secondsLeft, isTimeUp, isRunning, setTimer } = useTimer({
    isUsingZeroPadding: true,
  })

  useEffect(() => {
    let disposer

    const fetchExamForTimerRunning = async () => {
      const { contents } = await studentHomeApi.fetchRecentStudies({
        materialViewType: 'EXAM',
        lastStudentHomeworkId: null,
        lastStudentExamId: null,
        lastStudentWorkbookRevisionId: null,
        lastStudentWorksheetId: null,
      })

      const targetMAATExam = contents.find(({ examType }) => examType === 'MAAT')

      if (targetMAATExam?.status === 'COMPLETE') {
        setHideTimer(true)
      }

      const isExamAttempted = targetMAATExam?.studentExamOpenDatetime !== null

      if (targetMAATExam?.studentExamId && isExamAttempted) {
        const { data } = await studentExamApi.getStudentExamRemainingTime(
          targetMAATExam.studentExamId,
        )

        setMAATExam(targetMAATExam)
        setTimer(data)
      }
    }

    if (!isRunning) {
      disposer = CustomEventService.MAATTimerOn.listen(() => {
        fetchExamForTimerRunning()
      })?.disposer
    }

    return () => {
      disposer?.()
    }
  }, [])

  useEffect(() => {
    CustomEventService.MAATTimerOn.dispatch()
  }, [])

  useEffect(() => {
    if (isTimeUp && MAATExam?.studentExamId && MAATExam.status !== 'COMPLETE') {
      service.submitAutoScoringMAATProblems(MAATExam.studentExamId.toString()).then(() => {
        recentStudyService.loadRecentStudies('ALL')
      })
      modalService.openModal(
        <Modal.Alert
          confirm={{
            children: '확인하기',
            onClick: () => {
              modalService.closeModal()
              navigate(routeName.student.defaultPath)
            },
          }}
          theme="primary"
        >
          <>
            시험시간이 종료되어
            <br />
            자동으로 제출되었습니다.
            <br />
            풀지 못한 문제는 오답처리 됩니다.
            <br />
            <br />
            결과지와 보고서는
            <br />
            12월 30일 이후 선생님께 요청하세요.
          </>
        </Modal.Alert>,
        { modalName: 'MAAT_timeUpModal' },
      )
    }
  }, [isTimeUp, MAATExam])

  if (!secondsLeft || hideTimer) return null

  return (
    <S.Container
      className={clsx(
        Number(secondsLeft === 60) && 'last-minute',
        Number(secondsLeft < 60) && 'under-last-minute',
      )}
    >
      <div className="common">
        <p>수학경시대회 종료까지</p>
        <div className={clsx('clock', Number(secondsLeft) <= 600 && 'time-almost-up')}>
          <p>{minute}</p>
          <p>:</p>
          <p>{second}</p>
        </div>
        <p>남음</p>
      </div>

      <div className="last-minute-notice">
        <strong>1분 뒤</strong>
        <p>시험이 종료됩니다</p>
      </div>

      <div className="common-under-last-minute">
        <p>수학경시대회 종료까지</p>
        <div className={clsx('clock', Number(secondsLeft) <= 600 && 'time-almost-up')}>
          <p>{minute}</p>
          <p>:</p>
          <p>{second}</p>
        </div>
        <p>남음</p>
      </div>
    </S.Container>
  )
}

export default MAATTimer

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 12px 16px;
    border-top: 1px solid ${colors.gray.$200};

    color: ${colors.gray.$900};
    background: #fff;

    > .common,
    > .common-under-last-minute {
      display: flex;
      gap: 4px;

      > .clock {
        display: flex;
        gap: 4px;

        padding: 0 8px;
        border-radius: 8px;

        color: ${colors.blue.$400};
        ${typo.body02};
        font-weight: 700;
        background: ${colors.blue.$100};

        &.time-almost-up {
          color: ${colors.red.$400};
          background: ${colors.red.$100};
        }
      }
    }

    > .last-minute-notice {
      opacity: 0;
      position: absolute;
      bottom: -21px;

      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;

      ${typo.body02};
    }

    > .common-under-last-minute {
      display: none;
      position: absolute;
      bottom: -21px;
    }

    &.last-minute {
      position: relative;
      min-height: 45px;
      > .common,
      > .last-minute-notice,
      > .common-under-last-minute {
        position: absolute;
        display: flex;
      }

      > .common {
        opacity: 0;
        transform: translateY(-32px);
        transition:
          transform 0.7s,
          opacity 0.25s 0.25s;
      }

      > .last-minute-notice {
        bottom: -21px;
        opacity: 1;
        transform: translateY(-32px);
        transition:
          transform 0.7s,
          opacity 0.25s 0.25s;
      }

      > .common-under-last-minute {
        display: flex;
        bottom: -21px;
        opacity: 0;
      }
    }

    &.under-last-minute {
      position: relative;
      min-height: 45px;

      > .common {
        display: none;
      }
      > .last-minute-notice {
        display: flex;
        opacity: 0;
        transform: translateY(-64px);
        transition:
          transform 0.7s,
          opacity 0.25s 0.25s;
      }

      > .common-under-last-minute {
        display: flex;
        opacity: 1;
        bottom: -21px;
        transform: translateY(-32px);
        transition:
          transform 0.7s,
          opacity 0.25s 0.25s;
      }
    }
  `,
}
