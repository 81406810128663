import * as S from '@effect/schema/Schema'
import { nonZeroNullable } from '@mathflat/shared/@common/schema/combinators'
import {
  ISODate,
  ISODateString,
  NonZeroNullableNumber,
} from '@mathflat/shared/@common/schema/schema'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'

import { CurriculumDomain } from '~/@entities'
import { AllGrade, SchoolType, Semester } from '~/@entities/(SchoolSystem)/schema'
import { AcademyDomain } from '~/@entities/Academy/module.ts'
import { StudentDomain } from '~/@entities/Student/domain.ts'
import { TeacherSchema } from '~/@entities/Teacher/schema'

import { ContentLevel } from '../module'
import type { Entity as WorkbookEntity } from './dto'

/**
 * *minPage, maxPage, answerPage 페이지의 경우
 * min max 페이지가 있다는 것은 인쇄할 수 있다는 의미이기도 하다.(저작권 문제 없는)
 * 근데 원본 시그니쳐 교재에서는 다른 의미로 사용된다.
 *
 * *서버에서 내려주는 defaultPageUrl, defaultAnswerPdfUrl의 경우
 * 서버에서 pdf를 만드는데 필요한 정보..? 같은 거라 프론트에서 알 필요가 없다.
 */

export module WorkbookDomain {
  export const TYPE = {
    시중교재: 'PUBLIC',
    교과서: 'SCHOOL',
    내교재: 'CUSTOM',
    커스텀시그니처교재: 'CUSTOM_SIGNATURE',
    시그니처교재: 'SIGNATURE',
  } as const

  export const SIGNATURE_TYPES = [
    'ARITHMETIC',
    'BASIC',
    'CONCEPT',
    'HIGH',
    'SCHOOL_TEST',
    'VACATION_BOOTCAMP',
  ]

  export type SIGNATURE_TYPE = (typeof SIGNATURE_TYPES)[number]
  export const SIGNATURE_TYPE: Record<string, SIGNATURE_TYPE> = {
    연산: 'ARITHMETIC',
    유형서: 'BASIC',
    개념서: 'CONCEPT',
    심화유형서: 'HIGH',
    내신대비: 'SCHOOL_TEST',
    방학특강: 'VACATION_BOOTCAMP',
  } as const

  export const SIGNATURE_TYPE_KO: Record<SIGNATURE_TYPE, string> = {
    ARITHMETIC: '연산',
    BASIC: '유형서',
    CONCEPT: '개념서',
    HIGH: '심화유형서',
    SCHOOL_TEST: '내신대비',
    VACATION_BOOTCAMP: '방학특강',
  } as const

  export const CUSTOM_SIGNATURE_FEATURE_TYPE = {
    체험교재: 'SAMPLE',
    대여교재: 'RENT',
    구매교재: 'OWN',
    표지편집_전_교재: 'DRAFT',
    표지_편집용_체험교재: 'COVER_SAMPLE',
  } as const

  export const ALLOWED_CUSTOM_SIGNATURE_FEATURE_TYPE_BY_PERMISSION = {
    PREMIUM: Object.values(CUSTOM_SIGNATURE_FEATURE_TYPE),
    BASIC: [CUSTOM_SIGNATURE_FEATURE_TYPE.체험교재, CUSTOM_SIGNATURE_FEATURE_TYPE.구매교재],
  }

  export type TYPE = typeof TYPE

  export const Id = S.number
  export const Type = S.enums(TYPE)
  export type Type = S.Schema.To<typeof Type>
  export const Level = ContentLevel

  // 마지막으로 채점한 page
  export const Page = NonZeroNullableNumber
  export type Page = S.Schema.To<typeof Page>
  export const PairX = nonZeroNullable(S.literal(1, 2, 3))
  export type PairX = S.Schema.To<typeof PairX>
  export const SignatureType = S.enums(SIGNATURE_TYPE)

  export type CustomSignatureFeatureType = ValueOf<typeof CUSTOM_SIGNATURE_FEATURE_TYPE>

  export const AssignedStudent = S.struct({
    id: S.number,
    studentName: S.string,
    studentStatus: StudentDomain.Status,
    workbookId: S.number,
    studentId: StudentDomain.Id,
  })
  export type AssignedStudent = S.Schema.From<typeof AssignedStudent>

  export const OptionalAssignedInfo = S.struct({
    assigned: S.boolean,
    list: S.array(AssignedStudent),
    lastAssignDateTime: ISODateString,
  })
}

export abstract class WorkbookCommon extends S.Class<WorkbookCommon>()({
  id: WorkbookDomain.Id,
  level: WorkbookDomain.Level,
  page: WorkbookDomain.Page, // 마지막으로 채점한 페이지
  schoolType: SchoolType,
  grade: AllGrade,
  semester: S.nullable(Semester), // grade가 과목인 경우 null
  title: S.string,
  fulltitle: S.string,
  assignmentFlag: S.boolean, // 출제가능여부 (출제 되었다가 아님)
  autoScored: S.boolean,
  coverType: S.number,
  conceptFlag: S.boolean, // 개념 여부
  createDatetime: ISODate,

  // 나중에 추가되어서 사용되어질 값?
  // lastUsePageNumber: S.nullable(S.number),
}) {
  abstract kind: ValueOf<typeof WorkbookDomain.TYPE>
}

// 시중교재
export class PublicWorkbook extends WorkbookCommon.extend<PublicWorkbook>()({
  subtitle: S.string,
  type: S.literal(WorkbookDomain.TYPE.시중교재),
  publisher: S.string,
  curriculumConceptType: CurriculumDomain.conceptType,
  pairX: WorkbookDomain.PairX,
  pairFlag: S.boolean,
}) {}

// 교과서
export class SchoolWorkbook extends WorkbookCommon.extend<SchoolWorkbook>()({
  subtitle: S.string,
  type: S.literal(WorkbookDomain.TYPE.교과서),
  edition: S.string,
  publisher: S.string,
  teacherId: TeacherSchema.Id,
  pairX: WorkbookDomain.PairX,
  pairFlag: S.boolean,
}) {}

export class CustomWorkbook extends WorkbookCommon.extend<CustomWorkbook>()({
  type: S.literal(WorkbookDomain.TYPE.내교재),
  headtitle: S.string,
  subtitle: S.string,
  minPage: S.number,
  maxPage: S.number,
  answerPage: S.number,
  curriculumConceptType: CurriculumDomain.conceptType,
  partitionType: S.number, // 교재 분할 타입
  pdfUrl: S.nullable(S.string),
  answerPdfUrl: S.nullable(S.string),
  academyId: AcademyDomain.Id,
  teacherId: TeacherSchema.Id,
}) {}
export class ignatureWorkbook extends WorkbookCommon.extend<CustomSignatureWorkbook>()({
  type: S.literal(WorkbookDomain.TYPE.시그니처교재),
  publisher: S.string,
  pdfUrl: S.string,
  pairX: WorkbookDomain.PairX,
  pairFlag: S.boolean,
  signatureType: WorkbookDomain.SignatureType,
  thumbnailImageUrl: S.string,
  signatureWorkbookFulltitle: S.string,
}) {}
export class CustomSignatureWorkbook extends WorkbookCommon.extend<CustomSignatureWorkbook>()({
  type: S.literal(WorkbookDomain.TYPE.커스텀시그니처교재),
  publisher: S.string,
  pdfUrl: S.string,
  pairX: WorkbookDomain.PairX,
  pairFlag: S.boolean,
  signatureType: WorkbookDomain.SignatureType,
  thumbnailImageUrl: S.string,
  signatureWorkbookFulltitle: S.string,
}) {}

export const Workbook = S.union(
  PublicWorkbook.struct,
  SchoolWorkbook.struct,
  CustomWorkbook.struct,
  CustomSignatureWorkbook.struct,
)

export type Workbook = S.Schema.To<typeof Workbook>
