import clsx from 'clsx'
import { observer } from 'mobx-react'
import { useRef } from 'react'

import { useIntersectionObserver } from '~/@common/hooks/useIntersectionObserver'
import type { StudentWorkbookDetailService } from '~/@pages/student/student-workbook/detail/@service/StudentWorkbookDetail.service'

import type { ProblemScoring } from '../@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '../@trait/ProblemScoringViewOption.trait'
import ProblemScoringBody from './ProblemScoringBody'
import S from './ProblemScoringCard.style'
import ProblemScoringFooter, { type ProblemScoringFooterProps } from './ProblemScoringFooter'
import ProblemScoringHeader from './ProblemScoringHeader'

type ProblemScoringCardProps = {
  problemScoring: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>
  viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
  onSubmitVideoAssist?: StudentWorkbookDetailService['onSubmitVideoAssist']
} & React.ComponentProps<'div'> &
  Pick<
    ProblemScoringFooterProps,
    'virtualKeybaordShowType' | 'isSubmittedAnswerAvailable' | 'onLocalUserInputChange'
  >

export const parts = { wrapper: 'ms__ProblemScoringCard' }

const ProblemScoringCard = ({
  problemScoring,
  viewOption,
  className,
  virtualKeybaordShowType,
  isSubmittedAnswerAvailable,
  onSubmitVideoAssist,
  onLocalUserInputChange,
  ...props
}: ProblemScoringCardProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const entry = useIntersectionObserver(containerRef, {
    freezeOnceVisible: true,
    deps: [
      problemScoring.localUserInput.isNotLocallyScored,
      viewOption.안푼문제만보기,
      viewOption.안푼문제만보기_노출여부,
      problemScoring.오답_모르는문제여부,
      viewOption.오답_모르는문제만보기,
      viewOption.오답_모르는문제만보기_노출여부,
    ],
  })

  const isVisible = !!entry?.isIntersecting

  // 유저가 local에서 아직 풀지 않은 경우 비노출
  if (
    viewOption.안푼문제만보기_노출여부 &&
    viewOption.안푼문제만보기 &&
    !problemScoring.localUserInput.isNotLocallyScored
  ) {
    return null
  }

  // 오답 모르는 문제만 보기하고, 오답 모르는 문제가 아닐 경우 비노출
  if (
    viewOption.오답_모르는문제만보기_노출여부 &&
    viewOption.오답_모르는문제만보기 &&
    !problemScoring.오답_모르는문제여부
  ) {
    return null
  }

  return (
    <S.ProblemScoringCardWrapper
      key={problemScoring.id}
      ref={containerRef}
      {...props}
      className={clsx(parts.wrapper, className)}
    >
      {isVisible && (
        <S.ProblemScoringCardInner>
          <ProblemScoringHeader
            problemScoring={problemScoring}
            viewOption={viewOption}
            isSubmittedAnswerAvailable={isSubmittedAnswerAvailable}
            onSubmitVideoAssist={onSubmitVideoAssist}
          />

          <ProblemScoringBody
            문제이미지={problemScoring.문제이미지}
            문제같이보기={viewOption.문제같이보기}
            showFooter={
              !viewOption.studentAppSetting || viewOption.studentAppSetting.일반채점_채점전정답공개
            }
          />

          <ProblemScoringFooter
            problemScoring={problemScoring}
            viewOption={viewOption}
            virtualKeybaordShowType={virtualKeybaordShowType}
            isSubmittedAnswerAvailable={isSubmittedAnswerAvailable}
            onSubmitVideoAssist={onSubmitVideoAssist}
            onLocalUserInputChange={onLocalUserInputChange}
          />
        </S.ProblemScoringCardInner>
      )}
    </S.ProblemScoringCardWrapper>
  )
}

export default observer(ProblemScoringCard)
