import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import {
  HandwrittenNoteControllerService,
  HandwrittenNoteModeService,
  type HandwrittenNoteTypes,
  ReadOnlyHandwrittenNote,
  WritableHandwrittenNote,
} from '@mathflat/handwritten-note'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import {
  type ReactEventHandler,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile as isMobileDevice } from 'react-device-detect'

import { handwrittenNoteApi } from '~/@common/api/handwrittenNoteApi'
import { useIntersectionObserver } from '~/@common/hooks/useIntersectionObserver'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import { IndexedDbService } from '~/@common/services/indexedDb.service'
import { commonRepo } from '~/@common/services/repo.service.ts'
import { colors, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import type { Size } from '~/@common/utils/domUtils'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import type { ProblemScoringFooterProps } from '~/@pages/@common/(ProblemScoring)/ProblemScoringCard/ProblemScoringFooter'
import { NoteScaleResetButton } from '~/@pages/@common/NoteScaleResetButton'
import HandwrittenNoteMinimalToolbar from '~/@pages/student/@widgets/handwritten-note/HandwrittenNoteMinimalToolbar.tsx'
import HandwrittenNoteToolbar from '~/@pages/student/@widgets/handwritten-note/HandwrittenNoteToolbar'
import { VerticalDivider } from '~/@pages/student/@widgets/VerticalDivider'
import { useLearningProcessReference } from '~/@pages/student/learning-process/@common/hooks/useGetLearningProcessReference.ts'
import { REFERENCE_TYPE_STUDENT_WORKSHEET } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service.ts'

import WorksheetScoringByOneBodyFooter from './(WorksheetScoringByOneSlideFooter)/WorksheetScoringByOneBodyFooter'

type NoteData = HandwrittenNoteTypes.NoteData

//TODO: 미제출학습지에서 contentId넘겨야함
/**
 * **StudentLearningContentsProblem**
 * @param titleIngredients -
 * 제목을 구성하는 요소들을 넣으면 제목이 생성됩니다.
 * (해당 요소가 없다면 자동으로 생략)
 * @param isLastProblem - 마지막 문제인지 여부
 * @param isSubmittable - 제출 가능한지 여부
 * @param problemScoring - 문제 채점 정보 (이 컴포넌트에서는 **학습지 채점**에만 해당)
 * @param onNextOrSubmit - 다음 문제로 넘어가거나 제출하기 버튼을 눌렀을 때 실행되는 함수
 */
export const WorksheetScoringByOneSlide = observer(
  ({
    titleIngredients,
    isLastProblem,
    isSubmittable,
    problemScoring,
    problemsCount,
    viewOption,
    onNextOrSubmit,
    isLastScreen = false,
    disableWritting = false,
    contentId,
    type,
    containerTopNode,
    footerNode,
    onLocalUserInputChange,
  }: {
    titleIngredients: string[]
    isLastProblem: boolean
    isSubmittable: boolean
    problemScoring: ProblemScoring<'WORKSHEET'>
    viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'> | undefined
    onNextOrSubmit: () => void
    isLastScreen?: boolean
    problemsCount?: number
    disableWritting?: boolean
    type: WorksheetEntity.Worksheet['type']
    contentId: string
    containerTopNode?: ReactNode
    footerNode?: ReactNode
    onLocalUserInputChange?: ProblemScoringFooterProps['onLocalUserInputChange']
  }) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const entry = useIntersectionObserver(containerRef, {
      freezeOnceVisible: true,
    })
    const isVisible = !!entry?.isIntersecting

    const [imageUrl, setImageUrl] = useState('')
    const [noteData, setNoteData] = useState<NoteData>()
    const [noteContentSize, setNoteContentSize] = useState<Size>()

    const [timestamp, setTimestamp] = useState<number>()
    const [isResetButtonDisplayed, setIsResetButtonDisplayed] = useState(false)

    const noteContainerRef = useRef<HTMLDivElement>(null)

    const resetNoteScale = () => {
      setTimestamp(Date.now())
      setIsResetButtonDisplayed(false)
    }

    const updateIsResetButtonDisplay = ({ changed }: HandwrittenNoteTypes.TransformEventProps) => {
      setIsResetButtonDisplayed(!changed)
    }

    const indexedDbService = useRepository(IndexedDbService)
    const handwrittenNoteModeService = useRepository(HandwrittenNoteModeService)

    const [noteControllerService] = useState(
      new HandwrittenNoteControllerService(handwrittenNoteModeService),
    )

    const title = titleIngredients.filter((title) => title).join(VerticalDivider)

    const handleImageLoad: ReactEventHandler<HTMLImageElement> = (e) => {
      const contentSize = {
        width: 1200,
        height: Math.max(729 - 40, e.currentTarget.height + 300),
      }
      setNoteContentSize(contentSize)
      setImageUrl(problemScoring.문제이미지!)
    }

    const handleImageError: ReactEventHandler<HTMLImageElement> = (e) => {
      e.currentTarget.onerror = null
    }

    const noteReadFunc = useCallback(async () => {
      try {
        const data = await indexedDbService.getStudentWorksheetNote({
          studentWorksheetId: +contentId,
          worksheetProblemId: problemScoring.id,
        })
        if (data?.noteData) {
          return data.noteData
        }
      } catch (err) {
        errorHandlerService.handle(err)
      }
    }, [indexedDbService, problemScoring.id, contentId])

    const noteWriteFunc = useCallback(
      async (data: NoteData) => {
        try {
          await indexedDbService.putStudentWorksheetNote({
            studentWorksheetId: +contentId,
            worksheetProblemId: problemScoring.id,
            noteData: data,
            timestamp: new Date().getTime(),
          })
        } catch (err) {
          errorHandlerService.handle(err)
        }
      },
      [indexedDbService, problemScoring.id, contentId],
    )

    useEffect(() => {
      if (!isVisible) {
        return
      }

      if (problemScoring.handwrittenNoteUrl) {
        handwrittenNoteApi
          .fetchNoteByUrl(problemScoring.handwrittenNoteUrl)
          .then((data) => {
            if (data) {
              setNoteData(data)
            }
          })
          .catch(errorHandlerService.handle)
      }
    }, [isVisible, problemScoring.handwrittenNoteUrl])

    const problemImage = useMemo(() => {
      return <img src={imageUrl} width={344} alt="문제이미지" />
    }, [imageUrl])

    const isShowHandwrittenNoteToolbar =
      !problemScoring.isSubmitted && noteContentSize && !disableWritting

    const referenceParam = useLearningProcessReference()

    const isAvailableAiTutor =
      referenceParam?.referenceType !== REFERENCE_TYPE_STUDENT_WORKSHEET &&
      commonRepo.Ai튜터_사용가능_여부

    return (
      <S.Container
        ref={containerRef}
        // swiper의 slidesPerView의 옵션이 문제 총 개수가 2개 이하와 초과일때가 달라 깨지는 style로 인해 나눠서 적용
        className={clsx(
          problemsCount !== undefined && problemsCount <= 2 ? 'fullWidth' : 'minWidth',
        )}
      >
        {isVisible && (
          <>
            {containerTopNode ?? (
              <div className="container__top">
                <strong className="top__problemIndex">{problemScoring.문제번호}</strong>
                {problemScoring.문제정답타입 === 'MULTIPLE_CHOICE' && (
                  <strong className="top__answerAmount">
                    정답 {problemScoring.문제정답길이}개
                  </strong>
                )}
                <strong
                  className="top__subjectSummary"
                  css={css`
                    ${textEllipsis(1)};
                  `}
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
                {isShowHandwrittenNoteToolbar && (
                  <div
                    className="top__toolbarContainer"
                    style={{
                      marginRight: type === WorksheetDomain.TYPE.자기주도학습 ? '160px' : '-12px',
                    }}
                  >
                    {(!isAvailableAiTutor || type !== WorksheetDomain.TYPE.자기주도학습) && (
                      <HandwrittenNoteToolbar
                        top={-31}
                        uiMode="simple"
                        controllerService={noteControllerService}
                      />
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="container__bottom" ref={noteContainerRef}>
              {isShowHandwrittenNoteToolbar && isResetButtonDisplayed && (
                <NoteScaleResetButton
                  onClick={resetNoteScale}
                  style={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                  }}
                />
              )}
              {type === WorksheetDomain.TYPE.자기주도학습 &&
                noteContainerRef.current &&
                isAvailableAiTutor && (
                  <HandwrittenNoteMinimalToolbar
                    controllerService={noteControllerService}
                    containerEl={noteContainerRef.current}
                    containerPadding={20}
                    right={isResetButtonDisplayed ? 72 : 20}
                    top={16}
                  />
                )}
              <div className="problem-area">
                {problemScoring.isSubmitted ? (
                  noteData ? (
                    <ReadOnlyHandwrittenNote
                      noteData={noteData}
                      isHidden={false}
                      preventScale={!isMobileDevice}
                      style={{
                        overflow: isMobileDevice ? 'hidden' : 'auto',
                      }}
                    >
                      {problemImage}
                    </ReadOnlyHandwrittenNote>
                  ) : (
                    problemImage
                  )
                ) : noteContentSize && !disableWritting ? (
                  <>
                    <WritableHandwrittenNote
                      key={timestamp}
                      contentSize={noteContentSize}
                      controllerService={noteControllerService}
                      readFunc={noteReadFunc}
                      writeFunc={noteWriteFunc}
                      saveDelay={100}
                      preventOverflow={isMobileDevice}
                      onTransformChange={updateIsResetButtonDisplay}
                      style={{
                        paddingBottom: '20px',
                        backgroundColor: colors.gray.$500,
                        overflow: 'hidden',
                      }}
                    >
                      {problemImage}
                    </WritableHandwrittenNote>
                  </>
                ) : (
                  problemImage
                )}
                {problemScoring.문제이미지 && (
                  <img
                    src={problemScoring.문제이미지}
                    onError={handleImageError}
                    onLoad={handleImageLoad}
                    alt=""
                    css={_preloadImageCss}
                  />
                )}
              </div>
              <div className="swiper-lazy-preloader swiper-lazy-preloader-black" />
              {footerNode ?? (
                <WorksheetScoringByOneBodyFooter
                  type={type}
                  isLastProblem={isLastProblem}
                  isSubmittable={isSubmittable}
                  problemScoring={problemScoring}
                  onNextOrSubmit={onNextOrSubmit}
                  viewOption={viewOption}
                  isLastScreen={isLastScreen}
                  onLocalUserInputChange={onLocalUserInputChange}
                />
              )}
            </div>
          </>
        )}
      </S.Container>
    )
  },
)

const S = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;

    &.minWidth {
      min-width: 384px;
    }

    &.fullWidth {
      width: 100%;
    }

    .container__top {
      display: flex;
      align-items: center;
      flex: 0 0 70px;
      padding: 20px;
      padding-right: 32px;
      gap: 10px;
      border-top-right-radius: 14px;
      border-top-left-radius: 14px;
      min-width: 384px;
      background-color: ${colors.white};
      border-bottom: 1px solid ${colors.gray.$300};
      ${mediaQuery.underTablet} {
        padding: 0 20px;
        flex: 0 0 38px;
      }
    }

    .container__top-title {
      font-weight: 700;
      color: ${colors.gray.$900};
      ${typo.body01}
    }
    .container__top-guide {
      display: flex;
      gap: 10px;
      color: ${colors.gray.$800};
      ${typo.caption01}
    }
    .top__guid-round {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .top__subjectSummary {
      width: 100%;
      min-width: 30%;
      color: ${colors.gray.$700};
      ${typo.body02}
    }
    .top__problemIndex {
      min-width: max-content;
      color: ${colors.gray.$900};
      ${typo.body01}
    }
    .top__answerAmount {
      padding: 4px 12px;
      border-radius: 8px;
      background-color: ${colors.gray.$200};
      color: ${colors.gray.$800};
      min-width: max-content;
      ${typo.body02}
    }
    .top__toolbarContainer {
      width: 340px;
      position: relative;
      flex-shrink: 0;
      margin-left: auto;
    }

    .container__bottom {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom-right-radius: 14px;
      border-bottom-left-radius: 14px;
      padding: 20px;
      background-color: ${colors.white};
      flex: 1 1 100%;
      overflow: hidden;

      .ms__HandwrittenNoteMinimalToolbar {
        z-index: 10;
      }
    }

    .container__bottom-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      row-gap: 25px;
      column-gap: 20px;
      overflow: scroll;
    }
    .problem-area {
      flex: 1 1 100%;
      overflow: auto;
    }
  `,
}
const _preloadImageCss = css`
  position: absolute;
  left: -999999px;
`
