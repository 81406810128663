import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import clsx from 'clsx'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import { useEffect, useRef } from 'react'

import { MAAT_ROUND1_DATE } from '~/@common/constants/MAAT'
import { useDraggable } from '~/@common/hooks/useDraggable'
import { CustomEventService } from '~/@common/services/event.service'
import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import type { StyleProps } from '~/@common/types'
import { VirtualKeyboardFloater } from '~/@common/ui/(Keypad)'
import { virtualKeyboardService } from '~/@common/ui/(Keypad)/keypad.service'
import { Icon } from '~/@common/ui/Icon/Icon'
import { MainModule } from '~/@pages/@widgets/Main'

import type { ProblemScoringColl } from '../(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '../(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import ProblemScoringCard from '../(ProblemScoring)/ProblemScoringCard/ProblemScoringCard'
import type { ProblemScoringFooterProps } from '../(ProblemScoring)/ProblemScoringCard/ProblemScoringFooter'
import { CardGridContainerModule } from '../CardGridContainer'
import CardGridContainer from '../CardGridContainer/CardGridContainer'
import type { Props as StudentEduMaterialScoringDashboardProps } from '../StudentEduMaterialScoringDashboard'
import { StudentEduMaterialScoringDashboard } from '../StudentEduMaterialScoringDashboard'

type Props<T extends 'WORKSHEET' | 'WORKBOOK'> = {
  type: T
  viewOption?: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
  problemScoringColl?: ProblemScoringColl<T>
  score: StudentEduMaterialScoringDashboardProps['score']
  scoring: StudentEduMaterialScoringDashboardProps['scoring']
  onLocalUserInputChange?: ProblemScoringFooterProps['onLocalUserInputChange']
} & StyleProps

export const parts = { wrapper: 'ms__QuickScoring' }

// 학습지류 = 빠른채점 리스트
// 교재 = detail
export const QuickScoringTemplate = observer(
  <T extends 'WORKSHEET' | 'WORKBOOK'>({
    viewOption,
    problemScoringColl,
    className,
    type,
    score,
    scoring,
    onLocalUserInputChange,
    ...props
  }: Props<T>) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const droppableEl = document.querySelector(`.${MainModule.base}`) as HTMLElement

    const { isDragging, dragDelta, draggableRef } = useDraggable<HTMLElement>({
      containerEl: droppableEl,
    })

    useEffect(() => {
      const { disposer } = CustomEventService.alltooltipOff.listen(scrollRef.current)
      return () => {
        disposer()
      }
    }, [scrollRef, viewOption])

    if (!viewOption) return null

    const showDashboard = (() => {
      if (viewOption.content.type === 'MAAT') {
        return false
      }

      if (viewOption.content.status !== CONTENT_STATUS.전체채점) {
        return true
      }

      if (viewOption.content.kind === 'WORKSHEET') {
        return true
      }

      if (viewOption.content.kind === 'WORKBOOK') {
        return (
          viewOption.content.type === 'CUSTOM_SIGNATURE' || viewOption.content.type === 'CUSTOM'
        )
      }
    })()

    const eduMaterialType = viewOption.content.type === 'MAAT' ? 'MAAT' : type

    return (
      <S.QuickScoringTemplate
        {...props}
        className={clsx(parts.wrapper, viewOption.문제같이보기 && '문제같이보기', className)}
        ref={scrollRef}
      >
        {showDashboard && (
          <div className="dashboard-wrapper">
            <StudentEduMaterialScoringDashboard
              eduMaterialType={eduMaterialType}
              scoring={scoring}
              score={score}
              showScore={viewOption.content.type !== 'MAAT'}
              status={viewOption.content.status}
            />
            {viewOption.content.type === 'MAAT' && (
              <p className="dashboard-description">
                <Icon name="icon_alert_error_fill" color={colors.gray.$500} size={20} />
                결과지는 {format(MAAT_ROUND1_DATE.결과확인, 'M월 d일')} 선생님앱에서 확인
                가능합니다.
              </p>
            )}
          </div>
        )}
        {viewOption.content.status !== CONTENT_STATUS['채점전'] &&
        viewOption.오답_모르는문제만보기 &&
        problemScoringColl?.toWrongOrUnknwonArr.length === 0 ? (
          <S.Empty className="hasNotWrongOrUnknown">오답/모르는 문제가 없습니다.</S.Empty>
        ) : (
          <CardGridContainer
            className="problem-card-grid"
            ratio={viewOption.문제같이보기 ? 'fr1' : 'fr3'}
          >
            {problemScoringColl?.toArr.map((problemScoring) => {
              return (
                <ProblemScoringCard
                  key={problemScoring.id}
                  problemScoring={problemScoring}
                  viewOption={viewOption}
                  isSubmittedAnswerAvailable={type !== 'WORKBOOK'}
                  onLocalUserInputChange={onLocalUserInputChange}
                />
              )
            })}
          </CardGridContainer>
        )}
        {virtualKeyboardService.show && virtualKeyboardService.showType === 'floater' && (
          <VirtualKeyboardFloater
            ref={draggableRef}
            style={{
              position: 'absolute',
              transform: `translate(${dragDelta.x}px, ${dragDelta.y}px)`,
              outline: isDragging ? `1px solid ${colors.blue.$500}` : 'none',
            }}
            closeKeyboard={() => virtualKeyboardService.close()}
            onKeyButtonClick={(e, latexNodeType) => {
              virtualKeyboardService.onKeyButtonClick(e, latexNodeType)
            }}
            keypadType={virtualKeyboardService.type}
          />
        )}
      </S.QuickScoringTemplate>
    )
  },
)

export const mobileStyle = css`
  flex-direction: column;
  gap: 0;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 0 1 100%;

  .${CardGridContainerModule.parts.gridContainer} {
    padding: 20px 0;
    height: auto;
  }

  .dashboard-wrapper {
    height: auto;
    padding: 10px 16px 0;
    margin: 0;
  }

  .dashboard-wrapper + .${CardGridContainerModule.parts.gridContainer} {
    padding: 10px 0;
  }
`

const S = {
  QuickScoringTemplate: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 24px;
    overflow-x: auto;

    &.문제같이보기 {
      height: 100%;
    }

    .dashboard-wrapper {
      display: flex;
      flex-direction: column;
      height: 450px;
      margin: auto 0;

      .dashboard-description {
        display: flex;
        gap: 2px;
        align-items: center;

        margin-top: 12px;
        color: ${colors.gray.$500};

        ${mediaQuery.underTablet} {
          justify-content: center;
        }
      }
    }

    ${mediaQuery.underTablet} {
      ${mobileStyle};
    }
  `,
  Empty: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
