import { useState } from 'react'
import { useNavigate } from 'react-router'

import { routeName } from '~/@common/constants'

import { initAxiosDefaultBaseURL, type LocalStorageDataT } from './envSettings'

export const ApiChangeButton = () => {
  const [api, setApi] = useState(localStorage.getItem('DEV_API') ?? 'dev')
  const order = ['dev', 'stg', 'custom'] as const
  const navigate = useNavigate()

  const getNextPhase = (phase: LocalStorageDataT['DEV_API']) => {
    let index = order.findIndex((item) => item === phase)
    index < 0 && (index = 2)
    return order[(index + 1) % order.length]
  }

  const changeApi = () => {
    const nextPhase = getNextPhase(api)
    let apiTypeOrBaseURL: LocalStorageDataT['DEV_API'] = nextPhase

    if (nextPhase === 'custom') {
      const custom = prompt(
        'http로 시작하는 커스텀 API DOMAIN을 입력해주세요.\n(ex: https://api-dev.mathflat.com)\n* CORS 대응은 되어있지 않습니다.',
      )
      if (!custom || !custom.startsWith('http')) {
        apiTypeOrBaseURL = 'dev'
      } else {
        apiTypeOrBaseURL = custom
      }
    }
    setApi(apiTypeOrBaseURL)
    initAxiosDefaultBaseURL(apiTypeOrBaseURL)
    navigate(routeName.login)
  }

  return (
    <button
      data-testid="api"
      css={{
        position: 'fixed',
        top: 60,
        left: 0,
        padding: 4,
        zIndex: 999999999,
        fontSize: 12,
        color: api === 'dev' ? '#0000cc' : '#cc0000',
        border: '1px solid black',
        borderColor: api === 'dev' ? '#0000cc' : '#cc0000',
      }}
      onClick={changeApi}
    >
      {api === 'dev' && 'dev'}
      {api === 'stg' && 'stg'}
      {api !== 'dev' && api !== 'stg' && 'custom'}
    </button>
  )
}
