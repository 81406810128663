import { strapi } from '~/@common/api/strapiApi'
import { remoteStorageAxios } from '~/@common/services/remoteStorage.service'
import chatAxios from '~/@common/utils/chatAxios'
import maxios from '~/@common/utils/maxios'

export type LocalStorageDataT = {
  DEV_API: string
}

export const initAxiosDefaultBaseURL = (type: LocalStorageDataT['DEV_API'] | string) => {
  localStorage.setItem('DEV_API', type)
  switch (type) {
    case 'dev': {
      maxios.defaults.baseURL = 'https://api-dev.mathflat.com'
      strapi.defaults.baseURL = 'https://hcms-staging.mathflat.com/api'
      remoteStorageAxios.defaults.baseURL = 'https://remote-storage-dev.mathflat.com'
      chatAxios.defaults.baseURL = 'https://ai-dev.mathflat.com'
      return
    }
    case 'stg': {
      maxios.defaults.baseURL = 'https://api-staging.mathflat.com'
      strapi.defaults.baseURL = 'https://hcms-staging.mathflat.com/api'
      remoteStorageAxios.defaults.baseURL = 'https://remote-storage-staging.mathflat.com'
      chatAxios.defaults.baseURL = 'https://ai-staging.mathflat.com'
      return
    }
    default: {
      maxios.defaults.baseURL = type
      strapi.defaults.baseURL = 'https://hcms.mathflat.com/api'
      remoteStorageAxios.defaults.baseURL = 'https://remote-storage.mathflat.com'
      chatAxios.defaults.baseURL = 'https://ai.mathflat.com'
    }
  }
}
