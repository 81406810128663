import { Entity as CurriculumEntity } from '@mathflat/domain/@entities/Curriculum/dto.ts'
import { observer, useLocalObservable } from 'mobx-react'
import type { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { DROPDOWN_ITEM_HEIGHT } from '~/@common/constants'
import { useOrientation } from '~/@common/hooks/useOrientation'
import { useRepository } from '~/@common/hooks/useRepository'
import { commonRepo } from '~/@common/services/repo.service'
import { DropdownItem } from '~/@common/ui/Dropdown'
import Dropdown from '~/@common/ui/Dropdown/Dropdown.tsx'
import { ChallengeStore } from '~/@pages/student/@common/store/Challenge.store'

import { WorkbookSearchService } from './WorkbookSearch.service'

interface Props {
  onChange?: (curriculumKey: string) => void
}

const WorkbookSchoolCurriculumDropDown: FC<Props> = ({ onChange }) => {
  const workbookSearchService = useRepository(WorkbookSearchService)
  const challengeStore = useRepository(ChallengeStore)
  const { type: orientationType } = useOrientation()

  const _store = useLocalObservable(() => ({
    onChangeCurriculum(curriculumKey: CurriculumEntity.CurriculumTree['trieKey']) {
      const studentId = commonRepo.studentId
      if (studentId) {
        workbookSearchService?.setCurriculumKey(curriculumKey)
        onChange?.(curriculumKey)
      }
    },
  }))

  const isMobileLandscape = isMobileOnly && orientationType === 'landscape'

  return (
    <Dropdown
      width={156}
      listHeight={isMobileLandscape ? DROPDOWN_ITEM_HEIGHT * 3 : undefined}
      value={workbookSearchService.curriculumKey ?? challengeStore.curriculumKey}
      onChange={(curriculumKey) => _store.onChangeCurriculum(curriculumKey)}
    >
      {commonRepo.curriculumTreeColl?.toArrWithoutOldCurriculum.map((schoolCurriculum) => (
        <DropdownItem key={schoolCurriculum.trieKey} value={schoolCurriculum.trieKey}>
          {CurriculumEntity.CurriculumTree.toShortLabel(schoolCurriculum.korSchoolGradeSemester)}
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

export default observer(WorkbookSchoolCurriculumDropDown)
