import styled from '@emotion/styled'
import qs from 'qs'
import { generatePath, useNavigate } from 'react-router'

import { routeName } from '~/@common/constants'
import { useRepository } from '~/@common/hooks/useRepository'
import { CustomEventService } from '~/@common/services/event.service'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import Modal from '~/@common/ui/modal/Modal'
import RecentStudyService from '~/@pages/student/student-home/@service/RecentStudy.service'

import { StudentExamService } from '../@service/StudentExamPage.service'

type Props = {
  title: string
  studentExamId: number
}

const StudentMAATExamModal = ({ title, studentExamId }: Props) => {
  const service = useRepository(StudentExamService)
  const recentStudyService = useRepository(RecentStudyService)
  const navigate = useNavigate()

  return (
    <Modal.Confirm.Positive
      confirm={{
        children: '응시하기',
        onClick: async () => {
          try {
            await service.startExam(studentExamId)
            modalService.closeModal()
            navigate({
              pathname: generatePath(routeName.student.studentExamScoringById, { studentExamId }),
              search: qs.stringify({ title, examType: 'MAAT' }),
            })
            CustomEventService.MAATTimerOn.dispatch()
          } catch (error) {
            modalService.closeModal()
            modalService.openModal(
              <Modal.Alert
                confirm={{
                  children: '확인하기',
                  onClick: () => {
                    recentStudyService.loadRecentStudies('ALL')
                    navigate(routeName.student.defaultPath)
                    modalService.closeModal()
                  },
                }}
              >
                응시 기간이 종료되었습니다
              </Modal.Alert>,
              { modalName: 'MAATExamEnd' },
            )
          }
        },
      }}
      cancel={{
        children: '다음에 하기',
        onClick: () => {
          modalService.closeModal()
        },
      }}
    >
      <S.Container>
        <article>
          <h6>응시 방법 및 유의사항</h6>
          <S.TextWithDot>
            응시 후 <strong className="red">90분</strong>이 지나면 자동으로 제출됩니다
          </S.TextWithDot>

          <S.TextWithDot>답안을 제출하고 나면 수정이 불가합니다.</S.TextWithDot>
          <S.TextWithDot>풀지 않은 문제는 오답으로 처리됩니다.</S.TextWithDot>
          <S.TextWithDot>로그아웃시 입력한 정답은 삭제됩니다.</S.TextWithDot>
        </article>
        <article>
          <h6>응시 전 주의사항</h6>
          <S.TextWithDot>안전한 네트워크 환경을 준비해주세요.</S.TextWithDot>
          <S.TextWithDot>충분한 배터리를 충전해주세요.</S.TextWithDot>
        </article>
      </S.Container>
    </Modal.Confirm.Positive>
  )
}

const S = {
  Container: styled.main`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .red {
      color: ${colors.red.$400};
      font-weight: bold;
    }

    > article {
      text-align: left;

      > h6 {
        font-weight: 700;
        ${typo.caption01};
      }

      > p,
      span {
        ${typo.caption01};
      }
    }
  `,
  TextWithDot: styled.p`
    position: relative;
    ${typo.caption01};
    margin-left: 16px;

    ::before {
      content: '';
      position: absolute;
      left: -9px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      background-color: ${colors.gray.$900};
      border-radius: 50%;
    }
  `,
}

export default StudentMAATExamModal
